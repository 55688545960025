import {Exercise, ExerciseTableData, PlanExercise, PlanExerciseTableData} from "../../../../types/domain";
import holidayData from "../../../../assets/holidays.json"
import Holidays from "date-holidays";
import {easter, EasterDate} from "date-easter";

type HolidayType = {
    date: Date,
    name: string
}

/**
 * Sort stages - Prophylaxe is printed as last stage
 * @param name1 name of the a stage
 * @param name2 name of a stage
 */
export function sortStages(name1: string, name2: string){
    let aNum = -1;
    let bNum = -1;
    if (name1 !== "Prophylaxe"){
        aNum = parseInt(name1)
    }
    if (name2 !== "Propyhlaxe"){
        bNum = parseInt(name2)
    }

    return aNum - bNum;
}

/**
 * Generate ExerciseTableData array from the array of exercises
 * @param exercises list of exercises
 */
export function generateExerciseTableData(exercises: Array<Exercise>){
    let tableData:ExerciseTableData[] =  [];
    exercises.forEach(exercise => {
        tableData.push({name: exercise.name, description: exercise.description,
            material: exercise.material, method: exercise.methods.map(m => m.code).join(', ')})
    })
    return tableData
}

/**
 * Generate PlanExerciseTableData array from the array of exercises
 * @param planExercises list of PlanExercises
 */
export function generatePlanExerciseTableData(planExercises: Array<PlanExercise> | null){
    let tableData:PlanExerciseTableData[] =  [];
    planExercises?.forEach(planExe => {
        tableData.push({name: planExe.exercise.name, description: planExe.exercise.description,
            material: planExe.exercise.material, method: planExe.exercise.methods.map(m => m.code).join(', '),
            execution: planExe.execution ? planExe.execution : "",
            interest: planExe.interest ? (planExe.interest === 3 ? "hoch" : planExe.interest === 2 ? "mittel" : "gering") : ""
        })
    })
    return tableData
}

/**
 * Sort the array of PlanExercises by the position attribute
 * @param planExercises list of PlanExercises
 */
export function sortPlanExercisesByPosition(planExercises: Array<PlanExercise>){
    return planExercises.sort((a: PlanExercise, b: PlanExercise) => {
        if (a.position && b.position){
            return a.position - b.position;
        }
        return 0;
    })
}

/**
 * Get holidays and season around the training date
 * @param trainingDate date of the planned training
 */
export function getHolidaysAndSeason(trainingDate: string){
    let trainDate = new Date(trainingDate);
    trainDate.setTime(trainDate.getTime() - (60 * 60 * 1000))  //set date to 00:00

    let twoWeeks = new Date(trainingDate);
    twoWeeks.setDate(twoWeeks.getDate() + 14);

    let holidays: HolidayType[] = [];

    // custom fixed holidays
    holidayData.holidays.forEach((holiday) => {
        let tokens = holiday.date.split('.')
        let date = new Date(new Date().getFullYear(), parseInt(tokens[1]) - 1, parseInt(tokens[0]))

        if (date.getTime() >= trainDate.getTime() && date.getTime() <= twoWeeks.getTime()){
            holidays.push({
                "date": date,
                "name": holiday.name
            })
        }
    })

    //custom seasons
    holidayData.seasons.forEach((season) => {
        let tokensStart = season.start.split('.')
        let tokensEnd = season.end.split('.')
        let start = new Date(new Date().getFullYear(), parseInt(tokensStart[1]) - 1, parseInt(tokensStart[0]))
        let end = new Date(new Date().getFullYear(), parseInt(tokensEnd[1]) - 1, parseInt(tokensEnd[0]))

        if ((trainDate.getTime() >= start.getTime() && trainDate.getTime() <= end.getTime()) ||
            (twoWeeks.getTime() >= start.getTime() && twoWeeks.getTime() <= end.getTime())){
            holidays.push({
                "date": end,
                "name": season.name
            })
        }
    })

    // general austrian holidays
    let hd = new Holidays("AT");
    hd.getHolidays(new Date().getFullYear()).forEach(holiday => {
        let date = new Date(holiday.date);
        if (date.getTime() >= trainDate.getTime() && date.getTime() <= twoWeeks.getTime()){
            holidays.push({
                "date": date,
                "name": holiday.name
            })
        }
    })

    // calculable holidays
    const calcHolidays = getCalculableHolidays(trainDate);
    calcHolidays.forEach((holiday) => {
        if (holiday.date.getTime() >= trainDate.getTime() && holiday.date.getTime() <= twoWeeks.getTime()){
            holidays.push(holiday);
        }
    })

    //sort by date and return only names
    return  holidays.sort((h1: HolidayType, h2: HolidayType) => {
                return h1.date.getTime() - h2.date.getTime()
            }).map(h => h.name)
}

/**
 * Get list of holidays that need to be calculated according to year
 * of the training date
 * @param trainDate date, where the training should be held
 */
function getCalculableHolidays(trainDate: Date){
    let holidays: HolidayType[] = [];

    //ash wednesday, carnival tuesday and rose monday
    const easterDate: EasterDate = easter(trainDate.getFullYear());
    let ashWednesday = new Date(easterDate.year, easterDate.month - 1, easterDate.day);
    ashWednesday.setDate(ashWednesday.getDate() - 46);  //always 46 days after


    holidays.push({
        "date": ashWednesday,
        "name": "Aschermittwoch"
    });


    let carnivalTuesday = new Date(ashWednesday.getTime()); //now carnival tuesday
    carnivalTuesday.setDate(carnivalTuesday.getDate() - 1); //now carnival tuesday
    holidays.push({
        "date": carnivalTuesday,
        "name": "Faschingdienstag"
    });

    let roseMonday = new Date(carnivalTuesday.getTime()); //now carnival tuesday
    roseMonday.setDate(roseMonday.getDate() - 1); //now carnival tuesday
    holidays.push({
        "date": roseMonday,
        "name": "Rosenmontag"
    })
    return holidays;
}