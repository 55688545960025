import http from "./http-common"

/**
 * Service class for accessing the FTP server
 * @author KMU
 */
class FTPService {
    url = `/ftp/`;

    getFile(path: string) {
        return http.post(this.url + `getFile`,
            path, {responseType: 'blob'});
    }
}

export default new FTPService();