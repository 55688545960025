import http from "./http-common"
import {Exercise} from "../types/domain";

/**
 * Service class for the Exercise type
 * @author KMU
 */
class ExerciseService {
    url = `/exercises/`;

    getAll() {
        return http.get<Array<Exercise>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<Exercise>(this.url + `${id}`);
    }

    create(data: Exercise) {
        return http.post<Exercise>(this.url + `create`, data);
    }

    update(data: Exercise, id: number) {
        return http.put<any>(this.url + `${id}`, data);
    }

    delete(id: number) {
        return http.delete<any>(this.url + `${id}`);
    }

    /**
     * Get exercises of one method with the given code
     * @param code code of the method
     * @return list of Exercises
     */
    getByMethod(code: string){
        return http.get<Array<Exercise>>(this.url + `getByMethod?code=${code}`)
    }

    /**
     * Get all exercise names of exercises,
     * which belong to the Method with the given code
     * @param code code of the method
     * @return list of strings with the exercise names
     */
    getNamesByMethod(code: string){
        return http.get<Array<String>>(this.url + `getNamesByMethod?code=${code}`)
    }

    /**
     * Get names of all exercises
     * @return list of strings with the names
     */
    getAllNames() {
        return http.get<Array<String>>(this.url + `getAllNames`);
    }

    /**
     * Get all descriptions that were used for exercises
     * with the given name
     * @param name name of the exercises
     * @return list of strings with the descriptions
     */
    getAllDesc(name: string) {
        return http.get<Array<String>>(this.url + `getDescByName?name=${name}`);
    }

    /**
     * Get all material that were used for exercises
     * with the given name and description
     * @param name name of the exercises
     * @param desc description of the exercises
     * @return list of strings with the materials
     */
    getAllMaterial(name: string, desc: string) {
        return http.post<Array<String>>(this.url + `getMaterialByNameAndDesc`,
            {"name": name, "desc": desc});
    }

    /**
     * Get Exercise by name, description and material
     * @param name name of the exercise
     * @param desc description of the exercise
     * @param mat material of the exercise
     * @return Exercise
     */
    getByNameDescMat(name: string, desc: string, mat: string){
        return http.post<Exercise>(this.url + `getByNameDescMat`,
            {"name": name, "desc": desc, "mat": mat});
    }
}

export default new ExerciseService();