import "../../css/views/footer/LogoFooter.css"

export default function LogoFooter(){
    return (
        <div className="logo-footer" style={{}}>
            <a href="https://www.alzheimerhilfe.at/" target="_blank">
                <img src={require('./../../assets/logo/MAS-Alzheimerhilfe-Logo.png')} alt="MAS-logo"
                     height={"60"}/>
            </a>
            <a href="https://fh-ooe.at/organisation/fh-ooe-forschung-und-entwicklung" target="_blank">
                <img src={require("../../assets/logo/FHO_FE_Logo_DE EPS.png")} alt="FH-F&E-icon"
                     height={"60"}/>
            </a>
        </div>
    );
}