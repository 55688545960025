import { ReactNode } from "react"

/**
 * @author KMU
 * Helper function to wrap element into a form
 */

type FormWrapperProps = {
    title: string
    children: ReactNode
}
export function FormWrapper({ title, children }: FormWrapperProps) {
    return (
        <>
            <h1 className="heading">{title}</h1>
            <div>
                {children}
            </div>
        </>
    )
}