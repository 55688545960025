import { ReactElement, useState } from "react"

/**
 * Helper function to navigate through a multistep form
 * @param steps ReactElements, whereas each element is a step in the form
 * @author KMU
 */
export function useMultistepForm(steps: ReactElement[]) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    /**
     * Go to next step in form
     */
    function next() {
        setCurrentStepIndex(i => {
            if (i >= steps.length - 1) return i
            return i + 1
        })
    }

    /**
     * Got to previous step in form
     */
    function back() {
        setCurrentStepIndex(i => {
            if (i <= 0) return i
            return i - 1
        })
    }

    return {
        step: steps[currentStepIndex],
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        next,
        back,
    }
}