import React, {FormEvent, useEffect, useState} from "react";
import '../../css/components/NewPlan.css';
import {useMultistepForm} from "./components/utils/form/useMultistepForm";
import {GeneralForm} from "./components/newPlanSteps/GeneralForm";
import {Plan, PlanFormData} from "../../types/domain";
import {ExercisesForm} from "./components/newPlanSteps/ExercisesForm";
import {useNavigate} from 'react-router-dom';
import TopicService from "../../services/topic.service";
import StateService from "../../services/state.service";
import CustomModal from "./components/utils/CustomModal";
import {exportExerciseList} from "./components/utils/export/exportExerciseList";
import PlanService from "../../services/plan.service";
import {Button} from "@mantine/core";
import AppHeader from "../header/AppHeader";
import {Container} from "react-bootstrap";
import {ModalType} from "../../types/UITypes";
import Cookies from "js-cookie";
import UseraccountService from "../../services/useraccount.service";
import {exportAttachments} from "./components/utils/export/exportAttachments";

/**
 * New plan page
 * @author KMU
 */

const INITIAL_DATA: PlanFormData = {
    topic: "",
    stages: [],
    serviceCenter: {
        id: 0,
        name: ""
    },
    trainingGroup: null,
    participant: null,
    date: "",
    prepTimeContent: 0,
    prepTimeOrg: 0,
    duration: 0,
    timeSupport: 0,
    newExercises: []
}

export default function NewPlan() {
    const navigate = useNavigate();

    //form data to be mapped to a plan
    const [data, setData] = useState(INITIAL_DATA)
    const [modal, setModal] = useState<ModalType>({
        show: false,
        title: "",
        body: "",
        decline: false,
        handleOk: (() => {}),
        handleCancel: (() => {})
    })

    /**
     * Helper function to update the data fields
     * @param fields fields with new values
     */
    function updateFields(fields: Partial<any>) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    /**
     * Update model with content
     * @param modal
     */
    function updateModal(modal: ModalType){
        setModal({
            ...modal,
            show: modal.show,
            title: modal.title,
            body: modal.body,
            decline: modal.decline,
            handleOk: modal.handleOk,
            handleCancel: modal.handleCancel
        })
    }

    //attribute to navigate between the form pages
    const {step,
        isFirstStep,
        isLastStep, back, next } =
        useMultistepForm([          //set form pages
            <GeneralForm {...data} updateFields={updateFields} updateModal={updateModal} />,
            <ExercisesForm {...data} updateFields={updateFields} />,
        ])

    /* Reset newExercises list for exercises form */
    useEffect(() => {
        updateFields({newExercises: [], stages: []})
    }, [])

    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });
    /**
     * Handler function to navigate back to the plan overview page
     */
    const handleBackToOverview = () =>  setModal({...modal,
        show: true,
        title: "Hinweis",
        body: "Achtung, jegliche Eingaben werden nicht gespeichert!",
        decline: true,
        handleOk: () => {
            //reset data
            setData(INITIAL_DATA);
            updateFields({newExercises: []})
            setModal({...modal, show: false});
            navigate('/plans', {replace: true})
        },
        handleCancel: () => setModal({...modal, show: false})
    });

    /**
     * Export exercises as pdf
     */
    async function exportExercisesEvent() {
        let name = ""
        let participants = null;

        if (data.participant !== null)
            name = data.participant.firstname + " " + data.participant.lastname
        else if (data.trainingGroup !== null)
            name = data.trainingGroup.name
            participants = data.trainingGroup?.participants

        await exportExerciseList(data.date, data.newExercises, name, participants);
    }

    /**
     * Export attachments as pdf
     */
    async function exportAttachmentsEvent() {
        let attachments: string[] = [];
        // get paths of all attachments and solution sheets
        data.newExercises.forEach(exe => {
            exe.attachments.forEach(attach => {
                attachments.push(attach.path);
                attachments.push(attach.solutionPath);
            })
        })

        let name = ""

        if (data.participant !== null)
            name = data.participant.firstname + " " + data.participant.lastname
        else if (data.trainingGroup !== null)
            name = data.trainingGroup.name

        await exportAttachments(attachments, name,data.date);
    }

    /**
     * Save plan in database
     * @param e form event
     */
    async function onSubmit(e: FormEvent) {
        e.preventDefault()
        if (!isLastStep) {
            //get dates without time
            let givenDate = new Date(data.date);
            let today = new Date()

            //compare date without time - date cannot be in past
            if (givenDate.setHours(0,0,0,0) < today.setHours(0,0,0,0)) {
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Das Datum kann nicht in der Vergangenheit liegen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })

            //date can be max one year in future
            } else if (givenDate.getFullYear() > today.getFullYear() + 1){  //plan can be in next year the latest
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Das Datum kann maximal im nächsten Jahr liegen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })

            //a stage needs to be selected
            } else if (data.stages.length === 0){
                setModal({...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es wurde dem Plan kein Stadium zugewiesen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {}
                })

            //either group or single training needs to be selected
            } else if ((data.trainingGroup === null || data.trainingGroup === undefined || data.trainingGroup?.id === 0)
                        && (data.participant === null || data.participant === undefined || data.participant?.id === 0)){
                setModal({...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es muss eine Gruppe oder ein Teilnehmer ausgewählt werden!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {}
                })
            } else {
                //navigate to next form page
                return next()
            }
        } else {
            //at least one exercise needs to be created
            if (data.newExercises.length === 0){
                setModal({...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Der Plan muss Übungen enthalten!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {}
                })
            } else {
                const state = (await StateService.getByName("in Planung")).data;
                let dbTopic = (await TopicService.getByName(data.topic)).data;

                //topic not in database yet
                if (typeof (dbTopic) === 'string') {
                    dbTopic = {
                        id: 0,
                        name: data.topic
                    }
                }

                //get user from database
                const dbUser = (await UseraccountService.getByName(Cookies.get('username')!)).data;

                let newPlan: Plan = {
                    id: 0,
                    date: data.date,
                    duration: data.duration,
                    prepTimeContent: data.prepTimeContent,
                    prepTimeOrg: data.prepTimeOrg,
                    timeSupport: data.timeSupport,
                    exercises: null,
                    stages: data.stages,
                    state: state,
                    topic: dbTopic,
                    group: data.trainingGroup,
                    participant: data.participant,
                    notes: [],
                    user: dbUser
                }

                await PlanService.createWholePlan(newPlan, data.newExercises);

                //reset data
                setData(INITIAL_DATA);
                updateFields({newExercises: []})

                // show success modal and redirect to overview page
                setModal({
                    ...modal,
                    show: true,
                    title: "Hinweis",
                    body: "Der neue Plan wurde erfolgreich gespeichert!",
                    decline: false,
                    handleOk: () => {
                        setModal({...modal, show: false});
                        navigate('/plans', {replace: true})
                    },
                    handleCancel: () => {
                    }
                })
            }
        }
    }

    return (
        <div>
            <AppHeader bioLogo={false} showHomeIcon={true} showNewPlanIcon={false} showUserIcon={true}/>
            <Container className="app-content-container">
                <form onSubmit={onSubmit}>
                    {step}
                    <div className="newPlanButtonRow">
                        <Button onClick={isFirstStep ? handleBackToOverview : back}>
                            {isFirstStep ? "Zurück zur Übersicht" : "Zurück"}
                        </Button>
                        {
                            isLastStep ? <Button onClick={exportExercisesEvent}>Übungsliste erstellen</Button> : <></>
                        }
                        {
                            isLastStep ? <Button onClick={exportAttachmentsEvent}>Anhänge herunterladen</Button> : <></>
                        }
                        <Button type="submit">{isLastStep ? "Speichern" : "Weiter"}</Button>
                    </div>
                </form>
                <CustomModal title={modal.title}
                             body={modal.body}
                             decline={modal.decline} showModal={modal.show} handleOk={modal.handleOk}
                             handleCancel={modal.handleCancel}
                />
            </Container>
        </div>
    )
}