import http from "./http-common"
import {ServiceCenter} from "../types/domain";

/**
 * Service class for the ServiceCenter type
 * @author KMU
 */
class ServiceCenterService {
    url = `/centers/`;
    
    getAll() {
        return http.get<Array<ServiceCenter>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<ServiceCenter>(this.url + `${id}`);
    }

    /**
     * Get the names of all ServiceCenters
     * @return list of strings with the names
     */
    getAllNames() {
        return http.get<Array<String>>(this.url + `getAllNames`);
    }
}

export default new ServiceCenterService();