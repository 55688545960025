import http from "./http-common"
import {State} from "../types/domain";

/**
 * Service class for the State type
 * @author KMU
 */
class StateService {
    url = `/states/`;

    /**
     * Get State by name
     * @param name name of the State
     * @return State entity
     */
    getByName(name: string) {
        return http.get<State>(this.url + `getByName/${name}`);
    }
}

export default new StateService();