import SocialMedia from "../header/SocialMedia";
import Logo from "../header/Logo";
import MainNavigation from "../header/MainNavigation";
import React from "react";


export default function Home() {
    return(
        <div className="homePage">
            <div className="header">
                <Logo bigLogo={true}/>
                <SocialMedia/>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-frauen.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{paddingTop: "4rem", paddingBottom: "4rem"}}>
                <MainNavigation height={150} gap={"5rem"}
                                fontSize={"1.5rem"} inHeader={false}/>
            </div>
        </div>
    );
};