import http from "./http-common"
import {Useraccount} from "../types/domain";

/**
 * Service class for the Useraccount type
 * @author NSC
 */
class UseraccountService {
    url = `/login/`;

    /**
     * Get Useraccount by login
     * @return Useraccount entity
     * @param data contains the login
     */
    checkLogin(data: {username: string, password: string}) {
        return http.post<Useraccount>(this.url + `getByLoginData`, data);
    }

    /**
     * Get Useraccount by username
     * @param name username
     * @return Useraccount
     */
    getByName(name: string){
        return http.get<Useraccount>(this.url + `getByUsername/${name}`)
    }
}

export default new UseraccountService();