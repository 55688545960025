import Logo from "./Logo";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import Cookies from "js-cookie";

type Props = {
    bioLogo: boolean
    showHomeIcon: boolean,
    showNewPlanIcon: boolean,
    showUserIcon: boolean
}
export default function AppHeader(props: Props){
    const height = 60;
    const fontSize = "1rem";
    const navigate = useNavigate();

    /**
     * log out a user (remove cookie) and navigate to '/'
     */
    function logout(){
        Cookies.remove("username");
        navigate('/', {replace: true})
    }

    return (
        <div className="header">
            <Logo bigLogo={props.bioLogo}/>
            <div style={{display: "flex", alignItems: "center", marginRight: "20rem", gap: "1rem"}}>
                {
                    props.showHomeIcon ?
                    <div className="icon-text">
                        <Link to="/home">
                            <img src={require('./../../assets/icons/startseite.png')} alt="startseite-icon"
                                 height={height}/>
                        </Link>
                        <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                            <b>Zur Startseite</b>
                        </p>
                    </div> : <></>
                }
                {
                    props.showNewPlanIcon ?
                <div className="icon-text">
                    <Link to="/newPlan">
                        <img src={require('./../../assets/icons/neuerplan.png')} alt="neuerplan-icon"
                             height={height}/>
                    </Link>
                    <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                        <b>Neuer Plan</b>
                    </p>
                </div> : <></>
                }
                {
                    props.showUserIcon ?
                        <div className="icon-text">
                            <img src={require('./../../assets/icons/Useraccount.png')} alt="user-icon"
                                 height={height}/>
                            <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                                <b>{Cookies.get('username') ? Cookies.get('username') : 'Nicht angemeldet'}</b>
                            </p>
                        </div> : <></>
                }
                {
                    Cookies.get('username') ?
                        <div className="icon-text">
                            <a onClick={logout} style={{cursor:"pointer"}}>
                                <img src={require('./../../assets/icons/logout.png')} alt="logout-icon"
                                     height={height}/>
                                <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                                    <b>Logout</b>
                                </p>
                            </a>
                        </div> : <></>
                }
            </div>
        </div>
    );
}