import React, {useEffect, useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from 'mantine-react-table';
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {Container, Row} from 'react-bootstrap';
import {Exercise, Plan} from "../../types/domain";
import PlanService from "../../services/plan.service";
import {useNavigate} from "react-router-dom";
import {exportExerciseList} from "./components/utils/export/exportExerciseList";
import {sortStages} from "./components/utils/helperFunctions";
import {exportDocSheet} from "./components/utils/export/exportDocSheet";
import {ActionIcon, Box} from "@mantine/core";
import {IconChecklist, IconEdit, IconFiles, IconReportSearch} from "@tabler/icons-react";
import AppHeader from "../header/AppHeader";
import Cookies from "js-cookie";
import {exportAttachments} from "./components/utils/export/exportAttachments";

/**
 * @author KMU
 * Page with the list of saved plans
 */

//Plan type for table
type TablePlan = {
    id: number,
    topicName: string,
    trainingTyp: string,
    groupParticipant: string,
    stages: string,
    date: string,
    state: string
}

export default function PlanList () {

    const navigate = useNavigate();

    const [data, setData] = useState<Array<TablePlan>>([]);  //plans from database

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<TablePlan>[]>(
        () => [
            {
                accessorKey: 'topicName',
                header: 'Thema'
            },
            {
                accessorKey: 'trainingTyp',
                header: 'Trainingsart'
            },
            {
                accessorKey: 'groupParticipant',
                header: 'Gruppen-/ Teilnehmername'
            },
            {
                accessorKey: 'stages',
                header: 'Stadium'
            },
            {
                accessorKey: 'date',
                header: 'Datum',
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.original.date.split('-').join('');
                    const b = rowB.original.date.split('-').join('');
                    return a.localeCompare(b);
                }
            },
            {
                accessorKey: 'state',
                header: 'Status'
            }
        ], []
    );

    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

    /**
     * First function that is called
     */
    useEffect(() => {
        retrievePlans();
    }, []);

    /**
     * Navigate to rework plan page
     * @param planId id of the plan to rework
     */
    async function reworkPlan(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        navigate('/reworkPlan', {replace: true, state: dbPlan})
    }

    /**
     * Export exercises of plan with given id
     * @param planId plan id
     */
    async function exportExercisesEvent(planId: number){
        let dbPlan = (await PlanService.get(planId)).data
        let exercises = Array<Exercise>();
        if (dbPlan.exercises !== null)
            dbPlan.exercises.forEach((planExercise) => {
                exercises.push(planExercise.exercise);
            });

        let name = "";
        let participants = null;
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name
            participants = dbPlan.group?.participants

        await exportExerciseList(dbPlan.date, exercises, name, participants);
    }

    /**
     * Export plan in document sheet
     * @param planId plan id
     */
    async function exportAsDocSheet(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        await exportDocSheet(dbPlan);
    }

    /**
     * Get all plans from the database and convert to table format
     */
    async function retrievePlans() {
        let plans: Array<Plan> = [];
        await PlanService.getByUsername(Cookies.get('username'))
            .then((response: any) => {
                plans = response.data;
            })
            .catch((e: Error) => {
                console.log(e);
            });

        //convert to table format
        let tablePlans: Array<TablePlan> = [];
        for (const plan of plans) {
            let tablePlan: TablePlan = {
                id: plan.id,
                topicName: plan.topic.name,
                trainingTyp: plan.participant ? "ET" : "GT",
                groupParticipant: plan.participant ? plan.participant.lastname : plan.group ? plan.group.name : "leer",
                stages: plan.stages.map(s => s.name).sort((a, b) => sortStages(a, b)).join('/'),
                date: plan.date,
                state: plan.state.name
            }
            tablePlans.push(tablePlan);
        }

        setData(tablePlans);
    }

    /**
     * Export attachments of plan with given id
     * @param planId plan id
     */
    async function exportAttachmentsEvent(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data

        let attachments: string[] = [];
        // get paths of all attachments and solution sheets
        dbPlan.exercises?.forEach(planExe => {
            planExe.exercise.attachments.forEach(attach => {
                attachments.push(attach.path);
                attachments.push(attach.solutionPath);
            })
        })

        let name = "";
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name

        await exportAttachments(attachments, name,dbPlan.date);
    }

    const table = useMantineReactTable<TablePlan>({
        columns,
        data,
        enableRowActions: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
            if(row.original.state === "in Planung"){
                return <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <ActionIcon color="blueish" onClick={() => reworkPlan(row.original.id)}><IconEdit/></ActionIcon>
                    <ActionIcon onClick={() => exportExercisesEvent(row.original.id)}><IconChecklist/></ActionIcon>
                    <ActionIcon onClick={() => exportAttachmentsEvent(row.original.id)}><IconFiles/></ActionIcon>
                </Box>
            } else if (row.original.state === "abgeschlossen"){
                return <ActionIcon onClick={() => exportAsDocSheet(row.original.id)}><IconReportSearch/></ActionIcon>
            }
        },
        localization: MRT_Localization_DE,
        enableColumnActions: false,
        paginationDisplayMode: "pages"
    });

    return (
        <div>
            <AppHeader bioLogo={false} showHomeIcon={true} showNewPlanIcon={true} showUserIcon={true}/>
            <Container className="app-content-container">
                <Row className="firstRow heading">
                    <h1>Übersicht Trainingspläne</h1>
                </Row>
                <Row>
                    <div className="planListTable">
                        <MantineReactTable
                            table={table}
                        />
                    </div>
                </Row>
            </Container>
        </div>
    );
}