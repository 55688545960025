import http from "./http-common"
import {GroupTrainingAttendance, Topic, Traininggroup} from "../types/domain";

/**
 * Service class for the GroupTrainingAttendance type
 * @author KMU
 */
class GroupTrainingAttendanceService {
    url = `/groupTrainingAttendances/`;

    getAll() {
        return http.get<Array<GroupTrainingAttendance>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<GroupTrainingAttendance>(this.url + `${id}`);
    }

    getByPlan(planId: number) {
        return http.get<Array<GroupTrainingAttendance>>(this.url + `getByPlan?planId=${planId}`);
    }

    create(data: GroupTrainingAttendance) {
        return http.post<GroupTrainingAttendance>(this.url + `create`, data);
    }
}

export default new GroupTrainingAttendanceService();