import {Component} from "react";
import {Modal} from "react-bootstrap";
import {Button} from "@mantine/core";

/**
 * @author KMU
 * Helper class to define a custom modal
 */

type Props = {
    title: string,
    body: string,
    showModal: boolean,
    decline: boolean
    handleOk: () => void
    handleCancel: () => void
};
type State = {};
export default class CustomModal extends Component<Props, State>{

    constructor(props: Props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    render(){
        return(
            <Modal show={this.props.showModal} onHide={this.props.handleOk} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.body}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.handleOk}>
                        OK
                    </Button>
                    {this.props.decline ?
                        <Button onClick={this.props.handleCancel}>
                            Abbrechen
                        </Button> : <></>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}