import http from "./http-common"
import {Method} from "../types/domain";

/**
 * Service class for the Method type
 * @author KMU
 */
class MethodService {
    url = `/methods/`;
    getAll() {
        return http.get<Array<Method>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<Method>(this.url + `${id}`);
    }

    /**
     * Get Method by code
     * @param code code of the Method
     * @return Method entity
     */
    getByCode(code: string) {
        return http.get<Method>(this.url + `getByCode?code=${code}`);
    }
}

export default new MethodService();