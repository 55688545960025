import { PDFDocument } from 'pdf-lib';
import FtpService from "../../../../../services/ftp.service";

/**
 * Helper function to export the attachments in a merged pdf
 * @param attachments names of the attachments
 * @param name name of the group or the participant
 * @param planDate date of the plan
 * @author KMU
 */
export async function exportAttachments(attachments: string[], name: string, planDate: string) {

    const mergedPdf = await PDFDocument.create();

    //add attachments
    for (const attachment of attachments) {
        // load attachment
        const attachmentBuffer = await (await FtpService.getFile(attachment)).data.arrayBuffer();
        // const attachmentBuffer = await fetch(attachment).then(res => res.arrayBuffer());

        //merge attachment to rest
        const attachmentDoc = await PDFDocument.load(attachmentBuffer);
        const pdfPages = await mergedPdf.copyPages(attachmentDoc, attachmentDoc.getPageIndices());
        pdfPages.forEach((page) => {
            mergedPdf.addPage(page);
        });
    }

    //save merged pdf in blob
    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], {type: "application/pdf"});

    // Automatically download the merged PDF without user interaction
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Anhänge_" + name + "_" + planDate + ".pdf";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Free up memory
}