import React from 'react';
import './css/App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import Startpage from "./views/appPages/Startpage";
import PlanList from "./views/appPages/PlanList";
import NewPlan from "./views/appPages/NewPlan";
import ReworkPlan from "./views/appPages/ReworkPlan";
import {MantineProvider} from "@mantine/core";
import Home from "./views/staticPages/Home";
import Impressum from "./views/staticPages/Impressum";
import Contact from "./views/staticPages/Contact";
import Login from "./views/staticPages/Login";
import TYB from "./views/staticPages/TYB";

/**
 * Main application that defines the routes
 * to the different pages
 */
export default function App() {
  const navigate = useNavigate();

  return (
    <MantineProvider
      theme={{
          primaryColor: 'blueish',
          colors: {
              "blueish": ["#e3fbff", "#d5f0f6", "#b2dfe6", "#8bccd7", "#6bbdca", "#55b3c2", "#46afbf", "#349aa8", "#238997", "#007785"],
              "salmon": ["#ffece6", "#ffdad2", "#f8b3a3", "#f28972", "#ed6748", "#ea502d", "#ea441e", "#d13512", "#ba2d0d", "#a32207" ]
          }
      }}
    >
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/tyb" element={<TYB/>}/>
          <Route path="/impressum" element={<Impressum/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/home" element={<Startpage/>}/>
          <Route path="/plans" element={<PlanList/>}/>
          <Route path="/newPlan" element={<NewPlan/>}/>
          <Route path="/reworkPlan" element={<ReworkPlan/>}/>
          <Route path="/login" element={<Login/>}/>
        </Routes>
    </MantineProvider>
  );
}