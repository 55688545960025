import Logo from "../header/Logo";
import MainNavigation from "../header/MainNavigation";
import React from "react";
import {Grid} from "@mantine/core";
import {Col} from "react-bootstrap";

export default function Contact() {
    const height = 200;
    return(
        <div className="contact">
            <div className="header">
                <Logo bigLogo={true}/>
                <div style={{display: "flex", gap: "4rem"}}>
                    <MainNavigation height={60} gap={"1rem"}
                                    fontSize={"1rem"} inHeader={true}/>
                    {/*<SocialMedia/>*/}
                </div>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-mannlinks.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{padding: "2rem 4rem", display: "flex", flexDirection: "column"}}>
                <h1><b>Kontakt</b></h1>
                <Grid style={{margin: 0, marginTop: "1rem"}}>
                    <Col>
                        <p>Mag. Gerald Kienesberger<br/>
                            Geschäftsführung MAS Alzheimerhilfe<br/>
                            gerald.kienesberger@mas.or.at <br/></p>
                        <p>MAS Alzheimerhilfe | Lindaustraße 28 (Eingang A, 2. Stock) | 4820 Bad Ischl |
                            Österreich <br/>
                            Tel.: +43 / 6132 / 214 10 | Fax: +43 / 6132 / 214 10-10 <br/>
                            alzheimerhilfe@mas.or.at | <a href="www.alzheimerhilfe.at"
                                                          target="_blank">www.alzheimerhilfe.at</a></p>
                        <p>
                            So kommen Sie direkt zu den 7 MAS Demenzservicestellen:<br/>
                            <b>DSS Bad Ischl</b> | Tel.: 0664 / 889 28 619 | dss.bad-ischl@mas.or.at<br/>
                            <b>DSS Braunau</b> | Tel.: 0664 / 458 00 71 | dss.braunau@mas.or.at<br/>
                            <b>DSS Gmunden</b> | Tel.: 0664 / 858 94 85 | dss.gmunden@mas.or.at<br/>
                            <b>DSS Linz Nord/Urfahr</b> | Tel.: 0664 / 213 99 77 | dss.linz-nord@mas.or.at<br/>
                            <b>DSS Kirchdorf/Micheldorf</b> | Tel.: 0664 / 854 66 94 | dss.micheldorf@mas.or.at<br/>
                            <b>DSS Ried/I.</b> | Tel.: 0664 / 854 66 92 | dss.ried-im-innkreis@mas.or.at<br/>
                            <b>DSS Rohrbach</b> | Tel.: 0664 / 854 66 99 | dss.rohrbach@mas.or.at<br/>
                        </p>
                        <a href="https://www.alzheimerhilfe.at/" target="_blank" rel="noreferrer">
                            <img src={require('./../../assets/logo/MAS-Alzheimerhilfe-Logo.png')} alt="MAS-logo"
                                 height={"60"}/>
                        </a>
                    </Col>
                    <Col>
                        <div>
                            <p>Susanne Schaller und Katharina Munk<br/>
                                Forschungsgruppe Bioinformatik, FH ÖO Campus Hagenberg<br/>
                                susanne.schaller@fh-hagenberg.at | katharina.munk@fh-hagenberg.at <br/>
                                Softwarepark 11-13 | 4232 Hagenberg im Mühlkreis<br/>
                                <a href="www.bioinformatics.fh-hagenberg.at"
                                   target="_blank" rel="noreferrer">www.bioinformatics.fh-hagenberg.at</a>
                            </p>

                            <p>Renate Kränzl-Nagl und Stephanie Schwarz<br/>
                                Forschungsgruppe IMPACT, FH ÖO Campus Linz<br/>
                                renate.kraenzl-nagl@fh-linz.at | stephanie.schwarz@fh-linz.at <br/>
                                Garnisonstraße 21 | 4020 Linz
                            </p>
                            <a href="https://fh-ooe.at/" target="_blank" rel="noreferrer">
                                <img src={require("../../assets/logo/FH_Logo_OOE.png")} alt="FH-icon"
                                     height={"60"} style={{marginRight: "1rem"}}/>
                            </a>
                            <a href="https://fh-ooe.at/organisation/fh-ooe-forschung-und-entwicklung" target="_blank">
                                <img src={require("../../assets/logo/FHO_FE_Logo_DE EPS.png")} alt="FH-F&E-icon"
                                     height={"60"}/>
                            </a>
                        </div>
                    </Col>
                </Grid>
            </div>
        </div>
    );
};