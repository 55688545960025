import {FormWrapper} from "../utils/form/FormWrapper"
import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import ServiceCenterService from "../../../../services/servicecenter.service";
import {Participant, ServiceCenter, Stage, Traininggroup} from "../../../../types/domain";
import TraininggroupService from "../../../../services/traininggroup.service";
import StageService from "../../../../services/stage.service";
import ParticipantService from "../../../../services/participant.service";
import {Button, Checkbox, Divider, Group, Modal, Radio, Select, TextInput} from "@mantine/core";
import {DateInput, DateValue} from "@mantine/dates";
import {ModalType} from "../../../../types/UITypes";
import {getHolidaysAndSeason} from "../utils/helperFunctions";
import TopicService from "../../../../services/topic.service";
import 'dayjs/locale/de';

/**
 * Form element to set the general information of a new plan
 * @author KMU
 */

type GeneralData = {
    topic: string,
    stages: Array<Stage>,
    serviceCenter: ServiceCenter,
    trainingGroup: Traininggroup | null,
    participant: Participant | null,
    date: string,
    prepTimeContent: number,
    prepTimeOrg: number,
    duration: number,
    timeSupport: number
}

//parent property that will be used in this element
type GeneralFormProps = GeneralData & {
    updateFields: (fields: Partial<GeneralData>) => void,
    updateModal: (modal: ModalType) => void,
}

export function GeneralForm({topic, stages, serviceCenter, trainingGroup, participant, date,
                                updateFields, updateModal}: GeneralFormProps) {

    const [centers, setCenters] = useState([{label: "", value: ""}]);
    //centers from database
    const [dbCenters, setDBCenters] = useState<ServiceCenter[]>([]);
    //currently selected center
    const [selCenter, setSelCenter] = useState<string | null>(serviceCenter.name);

    const [selTrainingKind, setSelTrainingKind] = useState<string>(
        trainingGroup !== null ? "GT" : participant !== null ? "ET" : "");
    const [groups, setGroups] = useState([{label: "", value: ""}]);
    //centers from database
    const [dbGroups, setDBGroups] = useState<Traininggroup[]>([]);
    //currently selected group
    const [selGroup, setSelGroup] = useState<string | null>(
        trainingGroup !== null ? trainingGroup?.name : null);

    const [participants, setParticipants] = useState([{label: "", value: ""}]);
    //participants from database
    const [dbParticipants, setDBParticipants] = useState<Participant[]>([]);
    //currently selected group
    const [selParticipant, setSelParticipant] = useState<string | null>(participant !== null ?
        participant.firstname + ";" + participant.lastname : null);

    //stages from the database
    const [dbStages, setStages] = useState<Stage[]>([]);
    const [selStages, setSelStages] = useState<string[]>(stages.map(s => s.name))

    //modal with topic suggestions
    const [modalOpen, setModalOpen] = useState(false);
    const[holidayTopicSuggestions, setHolidayTopicSuggestions] = useState<string[]>([]);
    const[eldestTopicSuggestions, setEldestTopicSuggestions] = useState<string[]>([]);


    /**
     *  Load data before showing the form
     */
    useEffect(() => {
        ServiceCenterService.getAll()
            .then((response: any) => {
                setDBCenters(response.data);
                let centers = []
                for (let i in response.data){
                    centers.push({label: response.data[i].name, value: response.data[i].name})
                }
                setCenters(centers);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        StageService.getAll()
            .then((response: any) => {
                setStages(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        //servicecenter was already selected
        if (serviceCenter.id !== 0){
            TraininggroupService.getByServiceCenterName(serviceCenter.name)
                .then((response: any) => {
                    setDBGroups(response.data);
                    let groups = [];
                    for (let i in response.data){
                        groups.push({label: response.data[i].name, value: response.data[i].name})
                    }
                    setGroups(groups);
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            ParticipantService.getByServiceCenterName(serviceCenter.name)
                .then((response: any) => {
                    setDBParticipants(response.data);
                    let participants = [];
                    for (let i in response.data){
                        participants.push({label: response.data[i].firstname + " " + response.data[i].lastname,
                            value: response.data[i].firstname + ";" + response.data[i].lastname})
                    }
                    setParticipants(participants);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [])

    /**
     * Change event of date value
     * @param newValue new date value
     */
    function dateChange(newValue: DateValue) {
        if (newValue) {
            const dateObj = new Date(newValue);

            const day = String(dateObj.getDate()).padStart(2, '0'); // Day with leading zero
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so +1
            const year = dateObj.getFullYear();

            // Custom format: DD-MM-YYYY
            const formattedDate = `${year}-${month}-${day}`;

            updateFields({date: formattedDate})
        }
    }

    /**
     * Change event of the servicecenter dropdown
     * Changing the servicecenter leads to a load of the
     * groups and participants
     * @param newValue name of the selected center
     */
    function serviceCenterChangeEvent(newValue: string){
        setSelCenter(newValue);
        if (newValue){
            const center = dbCenters.find((center) => center.name === newValue);
            updateFields({serviceCenter: center});

            TraininggroupService.getByServiceCenterName(newValue)
                .then((response: any) => {
                    setDBGroups(response.data);
                    let groups = [];
                    for (let i in response.data){
                        groups.push({label: response.data[i].name, value: response.data[i].name})
                    }
                    setGroups(groups);
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            ParticipantService.getByServiceCenterName(newValue)
                .then((response: any) => {
                    setDBParticipants(response.data);
                    let participants = [];
                    for (let i in response.data){
                        participants.push({label: response.data[i].firstname + " " + response.data[i].lastname,
                            value: response.data[i].firstname + ";" + response.data[i].lastname})
                    }
                    setParticipants(participants);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    /**
     * Change event of the groups dropdown
     * @param newValue name of the selected training group
     */
    function groupChangeEvent(newValue: string){
        setSelGroup(newValue);
        const group = dbGroups.find((group) => group.name === newValue);
        updateFields({trainingGroup: group});
    }

    /**
     * Change event of the participants dropdown
     * @param newValue name of the selected participant
     */
    function participantChangeEvent(newValue: string) {
        const names = newValue.split(';')
        const participant = dbParticipants.find((p) => p.firstname === names[0]
            && p.lastname === names[1]);
        updateFields({participant: participant})
        setSelParticipant(newValue);
    }

    /**
     * Change event of the stages checkboxes
     * @param selValues array of selected stages
     */
    function stageChangeEvent(selValues: string[]){
        setSelStages(selValues);

        stages = []
        selValues.forEach(val => {
            const stage = dbStages.find((stage) => stage.name === val)
            if (stage){
                stages.push(stage)
            }
        });
        updateFields({stages: stages});
    }

    /**
     * Change event of the training kind checkboxes
     * @param selValue selected training kind
     */
    function trainingKindChangeEvent(selValue: string) {
        setSelTrainingKind(selValue);
        if (selValue === "GT") {
            updateFields({participant: null, trainingGroup: {id: 0, name: "", participants: [], serviceCenter: undefined}})
        } else {
            updateFields({trainingGroup: null, participant: {id:0, firstname:"", lastname:"", serviceCenter: undefined}})
        }
    }

    /**
     * Suggest potential topics by checking if there is a holiday near
     * and getting 5 topics that were not used for the longest
     */
    async function suggestTopic() {
        if (date === ""){
            updateModal({
                show: true,
                title: "Fehlermeldung",
                body: "Es muss zuerst ein Datum gewählt werden!",
                decline: false,
                handleOk: () => updateModal({
                    show: false,
                    title: "",
                    body: "",
                    decline: false,
                    handleOk: () => {},
                    handleCancel: () => {}
                }),
                handleCancel: () => {}
            })
        } else {
            setHolidayTopicSuggestions(getHolidaysAndSeason(date));

            let suggestions: string[] = [];
            await TopicService.getNEldest(5).then((response: any) => {
                for (let i in response.data){
                    suggestions.push(response.data[i].name);
                }
            }).catch((e: Error) => {
                console.log(e);
            });

            setEldestTopicSuggestions(suggestions);
            setModalOpen(true);
        }
    }

    function closeModal() {
        setModalOpen(false)
    }

    function topicChange(selValue: string) {
        updateFields({topic: selValue});
        closeModal();
    }

    return (
        <FormWrapper title="Allgemeine Infos">
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="serviceCenter">Demenzservicestelle</Form.Label>
                        <Select
                            data={centers}
                            value={selCenter}
                            placeholder="Wähle eine Servicestelle aus"
                            onChange={serviceCenterChangeEvent}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="date">Datum</Form.Label>
                        <DateInput
                            id="date"
                            value={date !== "" ? new Date(date) : null}
                            required
                            onChange={newValue => dateChange(newValue)}
                            locale="de"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label>Trainingsart</Form.Label>
                        <br/>
                        <Radio.Group
                            name="trainigKindGroup"
                            onChange={trainingKindChangeEvent}
                            value={selTrainingKind}
                        >
                            <Group>
                                <Radio
                                    id="trainingKindBox-ET"
                                    label="Einzeltraining"
                                    value="ET"
                                    checked={participant !== null} />
                                <Radio
                                    id="trainingKindBox-GT"
                                    label="Gruppentraining"
                                    type="radio"
                                    value="GT"
                                    checked={trainingGroup !== null}/>
                            </Group>
                        </Radio.Group>
                    </FormGroup>
                </Col>
                <Col>
                    {
                        trainingGroup !== null ? <FormGroup className="newExerciseField">
                            <Form.Label htmlFor="group">Gruppe</Form.Label>
                            <Select
                                data = {groups}
                                value={selGroup}
                                onChange={groupChangeEvent}
                                placeholder= "Wähle eine Gruppe aus"
                            ></Select>
                        </FormGroup> : <></>
                    }
                    {
                        participant !== null ?
                            <FormGroup className="newExerciseField">
                                <Form.Label htmlFor="group">Teilnehmer</Form.Label>
                                <Select
                                    data = {participants}
                                    value={selParticipant}
                                    onChange={participantChangeEvent}
                                    placeholder= "Wähle einen Teilnehmer aus"
                                ></Select>
                            </FormGroup> : <></>
                    }
                </Col>
            </Row>
            <Row>
                <FormGroup className="newExerciseField">
                    <Form.Label>Stadium</Form.Label>
                    <br/>
                    <>
                        <Checkbox.Group
                            value={selStages} onChange={stageChangeEvent}>
                            <Group>
                            {
                                dbStages.map(stage =>
                                    <Checkbox
                                        label={stage.name}
                                        value={stage.name}/>
                                )
                            }
                            </Group>
                        </Checkbox.Group>
                    </>
                </FormGroup>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="topic">Thema</Form.Label>
                        <TextInput
                            id="topic"
                            required
                            value={topic}
                            onChange={e => updateFields({topic: e.target.value})}
                        />
                    </FormGroup>
                </Col>
                <Col className="newExerciseField" style={{display: "flex", alignItems: "flex-end"}}>
                    <Button onClick={suggestTopic}>Thema vorschlagen lassen</Button>
                </Col>
            </Row>
            <Modal opened={modalOpen} onClose={closeModal} title="Themenvorschläge">
                <Divider my="xs" label="Feiertage und Jahreszeit" style={{marginTop: 0}}/>
                <Radio.Group onChange={topicChange}>
                    <Group mt="xs">
                        {
                            holidayTopicSuggestions.map(ts =>
                                <Radio value={ts} label={ts} />
                            )
                        }
                    </Group>
                </Radio.Group>
                <Divider my="xs" label="Länger nicht durchgeführt"/>
                <Radio.Group onChange={topicChange}>
                    <Group mt="xs">
                        {
                            eldestTopicSuggestions.map(ts =>
                                <Radio value={ts} label={ts} />
                            )
                        }
                    </Group>
                </Radio.Group>
            </Modal>
        </FormWrapper>
    )
}