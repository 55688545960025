import React from "react";
import {ParticipantNoteContent} from "../../../../types/domain";
import {FormWrapper} from "../utils/form/FormWrapper";
import {Table, Textarea} from "@mantine/core";

/**
 * Form element to set the general information of plan
 * that is reworked
 * @author KMU
 */

type NotesData = {
    planNoteContent: string
    groupNoteContent: string | null,
    participantNoteContents: Array<ParticipantNoteContent>
}

//parent property that will be used in this element
type GeneralFormProps = NotesData & {
    updateFields: (fields: Partial<NotesData>) => void
}

export function NotesForm({planNoteContent, groupNoteContent, participantNoteContents, updateFields}: GeneralFormProps) {

    /**
     * Change event for the participant note textareas
     * @param participantName name of the participant separated by ;
     * @param e change event
     */
    function participantContentChange(participantName: string, e: React.ChangeEvent<HTMLTextAreaElement>) {
        const names = participantName.split(';')
        const index = participantNoteContents.findIndex((p) =>
            p.participant.firstname === names[0] && p.participant.lastname === names[1]);

        if (participantNoteContents){
            participantNoteContents[index].content = e.currentTarget.value;
            updateFields({participantNoteContents: participantNoteContents});
        }
    }

    return (
        <FormWrapper title="Anmerkungen">
            <h4 className="heading">Allgemeine Anmerkungen</h4>
            <Textarea value={planNoteContent}
                      onChange={(e) => updateFields({planNoteContent: e.currentTarget.value})}
                      placeholder="Hier können Sie alles anmerken, was Ihnen allgemein aufgefallen ist (z.B. Probleme mit dem Raum, der Technik, ...)."
            />

            {
                groupNoteContent !== null ? <div>
                    <hr></hr>
                    <h4 className="heading">Gruppenspezifische Anmerkungen</h4>
                    <Textarea value={groupNoteContent}
                              onChange={(e) => updateFields({groupNoteContent: e.currentTarget.value})}
                              placeholder="Hier können Sie alles anmerken, was Ihnen speziell zur Gruppe aufgefallen ist."
                    />
                </div> : <></>
            }
                <div>
                    <hr></hr>
                    <h4 className="heading">Teilnehmerspezifische Anmerkungen</h4>
                    <Table>
                        <thead>
                            <tr>
                                <th>Teilnehmer*in</th>
                                <th>Notiz</th>
                            </tr>
                        </thead>
                    {participantNoteContents.map(note =>
                        <tbody>
                            <tr key={note.participant.firstname + ";" + note.participant.lastname}>
                                <td>{note.participant.firstname + " " + note.participant.lastname}</td>
                                <td><Textarea value={note.content}
                                              onChange={e => participantContentChange(note.participant.firstname + ";" + note.participant.lastname, e)}
                                              placeholder="Hier können Sie alles anmerken, was Ihnen speziell zu diesem/r Teilnehmer*in aufgefallen ist."
                                />
                                </td>
                            </tr>
                        </tbody>
                        )
                    }
                    </Table>
                </div>
        </FormWrapper>
    );
}