import http from "./http-common"
import {Traininggroup} from "../types/domain";

/**
 * Service class for the Traininggroup type
 * @author KMU
 */
class TraininggroupService {

    url = `/groups/`;

    getAll() {
        return http.get<Array<Traininggroup>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<Traininggroup>(this.url + `${id}`);
    }

    /**
     * Get all Traininggroups of a ServiceCenter with the given name
     * @param name name of the ServiceCenter
     * @return list of Traininggroup entities
     */
    getByServiceCenterName(name: string) {
        return http.get<Array<Traininggroup>>(this.url + `getByServiceCenterName?name=${encodeURIComponent(name)}`);
    }
}

export default new TraininggroupService();