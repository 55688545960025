import http from "./http-common"
import {Participant} from "../types/domain";

/**
 * Service class for the Participant type
 * @author KMU
 */
class ParticipantService {
    url = `/participants/`
    getAll() {
        return http.get<Array<Participant>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<Participant>(this.url + `${id}`);
    }

    /**
     * Get all Participants of a ServiceCenter with the given name
     * @param name name of the ServiceCenter
     * @return list of Participant entities
     */
    getByServiceCenterName(name: string) {
        return http.get<Array<Participant>>(this.url + `getByServiceCenterName?name=${encodeURIComponent(name)}`);
    }
}

export default new ParticipantService();