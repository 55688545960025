import http from "./http-common"
import {Stage} from "../types/domain";

/**
 * Service class for the Stage type
 * @author KMU
 */
class StageService {
    url = `/stages/`;
    getAll() {
        return http.get<Array<Stage>>(this.url + `getAll`);
    }
}

export default new StageService();