import React, {useEffect, useMemo, useState} from "react";
import {Form, FormGroup} from "react-bootstrap";
import MethodService from "../../../../services/method.service";
import ExerciseService from "../../../../services/exercise.service";
import {Exercise, ExerciseTableData} from "../../../../types/domain";
import {FormWrapper} from "./form/FormWrapper";
import CustomModal from "./CustomModal";
import {ActionIcon, Button, Group, Paper, Radio, Select, Tabs, Textarea, TextInput} from "@mantine/core";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {IconSquareRoundedPlus} from "@tabler/icons-react";
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {generateExerciseTableData} from "./helperFunctions";

/**
 * @author KMU
 * Helper class to define the panel with the dropdowns
 * to create a new exercise for a plan
 */

type ExercisesData = {
    newExercises: Array<Exercise>,
    topicExercises: Array<Exercise>
}

//parent property that will be used in this element
type ExercisesFormProps = ExercisesData & {
    updateFields: (fields: Partial<ExercisesData>) => void
}
export function ExerciseCreatePanel({newExercises, topicExercises, updateFields}: ExercisesFormProps) {

    //current exercise that is created
    const [exercise, setExercise] = useState({
                                                 name: "",
                                                 description: "",
                                                 material: "",
                                                 method: ""
                                             });

    //all methods from database
    const [methods, setMethods] = useState([{label: "", value: ""}]);

    //all exercises from database
    const [exercises, setExercises] = useState({
                                                   names: [{label: "", value: ""}],
                                                   descriptions: [{label: "", value: ""}],
                                                   materials: [{label: "", value: ""}]
                                               });

    //new exercise or from database
    const [exerciseSource, setExerciseSource] = useState<string>("dbExe");

    //flag to handle modal
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleOk = () => setShowModal(false);

    const [data, setData] = useState<Array<ExerciseTableData>>(generateExerciseTableData(topicExercises));

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<ExerciseTableData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Übung/Tätigkeit'
            },
            {
                accessorKey: 'description',
                header: 'Beschreibung',
                size: 300
            },
            {
                accessorKey: 'method',
                header: 'Methode',
                size: 50,
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                }

            },
            {
                accessorKey: 'material',
                header: 'Material'
            }
        ], []
    );

    /**
     *  Load data before showing the form
     */
    useEffect(() => {
        MethodService.getAll().then((response: any) => {
            let methods = []
            for (let i in response.data){
                methods.push({label: response.data[i].code, value: response.data[i].code})
            }
            setMethods(methods);
        }).catch((e: Error) => {
            console.log(e);
        });
    }, [])

    /**
     * Update list with topic-specific exercises
     */
    useEffect(() => {
        setData(generateExerciseTableData(topicExercises));
    }, [topicExercises]);

    /**
     * Change event of the method dropdown
     * Changing the method leads to a load of the
     * names of all exercises
     * @param newValue newly selected method code
     */
    function methodChangeEvent(newValue: string) {
        if (newValue === null){
            //reset current exercise and exercises list
            setExercise({...exercise, name: "", description: "",
                material: "", method: ""});
            setExercises({...exercises, names: [], descriptions: [], materials: []});
        } else {
            setExercise({...exercise, method: newValue, name: "",
                description: "", material: ""})

            //get names of exercises belonging to selected method
            ExerciseService.getNamesByMethod(newValue)
                .then((response: any) => {
                    let exerciseNames = []
                    for (let i in response.data) {
                        exerciseNames.push({label: response.data[i], value: response.data[i]})
                    }
                    setExercises({...exercises, names: exerciseNames, descriptions: [], materials: []});
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    /**
     * Change event of the exercise name dropdown
     * Changing the exercise name leads to a load of the
     * descriptions of all exercises with the selected name
     * @param newValue newly selected exercise name
     */
    function nameChangeEvent(newValue: any){
        if (newValue === null){
            //reset current exercise and exercises descriptions and materials
            setExercise({...exercise, name: "", description: "", material: ""});
            setExercises({...exercises, descriptions: [], materials: []});
        } else {
            setExercise({...exercise, name: newValue, description: "", material: ""});
    
            //get all descriptions of exercises with selected name
            ExerciseService.getAllDesc(newValue)
                .then((response: any) => {
                    let exerciseDescs = []
                    for (let i in response.data){
                        exerciseDescs.push({label: response.data[i], value: response.data[i]})
                    }
                    setExercises({...exercises, descriptions: exerciseDescs, materials: []});
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    /**
     * Change event of the exercise description dropdown
     * Changing the exercise description leads to a load of the
     * materials of all exercises with the selected name and description
     * @param newValue newly selected exercise description
     */
    function descChangeEvent(newValue: any){
        if (newValue === null){
            //reset current exercise description and material and all exercises materials
            setExercise({...exercise, description: "", material: ""})
            setExercises({...exercises, materials: []});
        } else {
            setExercise({...exercise, description: newValue, material: ""})
            ExerciseService.getAllMaterial(exercise.name, newValue)
                .then((response: any) => {
                    let exerciseMaterials = []
                    for (let i in response.data){
                        exerciseMaterials.push({label: response.data[i], value: response.data[i]})
                    }
                    setExercises({...exercises, materials: exerciseMaterials});
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    /**
     * Change event of the exercise material dropdown
     * @param newValue newly selected exercise material
     */
    function materialChangeEvent(newValue: any){
        if (newValue === null){
            setExercise({...exercise, material: ""})
        } else {
            setExercise({...exercise, material: newValue})
        }
    }

    /**
     * Add current exercise to the list of new exercises
     */
    async function addExercise(){
        //check if exercise not already in list
        if (newExercises.some(e => e.name === exercise.name
            && e.description === exercise.description && e.material === exercise.material)){
            handleShow();
        } else {
            await addExeToNewExes(exercise.name, exercise.description, exercise.material, exercise.method);

            //reset current exercise and exercise list
            setExercise({description: "", material: "",
                name: "", method: ""})
            setExercises({...exercises, names: [], descriptions: [], materials: []});
        }
    }

    /**
     * Helper function to add an exercise to the list of new exercises
     * @param name exericse name
     * @param description exericse description
     * @param material exercise material
     * @param method exercise method
     */
    async function addExeToNewExes(name: string, description: string, material: string, method: string){
        const dbMethod = (await MethodService.getByCode(method)).data
        if (dbMethod){
            //check if exercise already in database
            let dbExercise = (await ExerciseService.getByNameDescMat(name, description, material)).data;
            if (typeof(dbExercise) === 'string'){   //not in db
                dbExercise = {
                    id: 0,
                    name: name,
                    description: description,
                    material: material,
                    methods: [dbMethod],
                    stages: [],
                    planExercises: [],
                    topics: [],
                    attachments: []
                }
            }

            let adaptNewExe = [... newExercises];
            adaptNewExe.push(dbExercise);

            //update list with new exercises
            updateFields({newExercises: adaptNewExe})
        }
    }

    /**
     * Change event of the exercise source checkboxes
     * @param selValue selected exercise source
     */
    function exerciseSourceChange(selValue: string) {
        setExerciseSource(selValue);
    }

    /**
     * Add topic-specific exercise to list of new exercises
     * @param name exercise name
     * @param description exercise description
     * @param material exercise material
     * @param method exercise method
     */
    async function addTopicExe(name: string, description: string, material: string, method: string) {
        // add exercise to newExercises

        //check if exercise not already in list
        if (newExercises.some(e => e.name === name
            && e.description === description && e.material === material)){
            handleShow();
        } else {
            await addExeToNewExes(name, description, material, method);
        }
    }

    const table = useMantineReactTable<ExerciseTableData>({
        columns,
        data,
        initialState: {
          pagination: {
              pageSize: 5,
              pageIndex: 0
          }
        },
        enableColumnActions: false,
        enableTopToolbar: false,
        positionPagination:"bottom",
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
            return <ActionIcon color="blueish" onClick={() => addTopicExe(row.original.name, row.original.description, 
                row.original.material, row.original.method)}>
                <IconSquareRoundedPlus/>
            </ActionIcon>
        },
        localization: MRT_Localization_DE,
        mantinePaginationProps: {
            showRowsPerPage: false
        }
    });

    return (
        <>
            <FormWrapper title="Tätigkeitstabelle">
                <Tabs defaultValue="all">
                    <Tabs.List>
                        <Tabs.Tab value="all">Alle Übungen</Tabs.Tab>
                        <Tabs.Tab value="specific">Themenspezifische Übungen</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="all">
                        <Paper shadow="xs" radius="xs" p="md" withBorder style={{marginTop: "1rem"}}>
                            <FormGroup className="newExerciseField">
                                <Form.Label>Methode</Form.Label>
                                <Select
                                    data={methods}
                                    onChange={methodChangeEvent}
                                    value={exercise.method}
                                    placeholder="Wähle eine Fördermethode aus"
                                    clearable
                                />
                            </FormGroup>

                            <Radio.Group name="exerciseSource"
                                         onChange={exerciseSourceChange}
                                         value={exerciseSource}
                                         className="newExerciseField"
                            >
                                <Group mt="xs">
                                    <Radio value="dbExe" label="Übung aus Datenbank" />
                                    <Radio value="newExe" label="Neue Übung erstellen" />
                                </Group>
                            </Radio.Group>

                            <Paper shadow="xs" radius="xs" p="md" withBorder className="newExerciseField">
                            {
                                exerciseSource === "newExe" ?
                                    // new exercise
                                    <div>
                                        <FormGroup className="newExerciseField">
                                            <Form.Label>Übung/Tätigkeit</Form.Label>
                                            <TextInput
                                                value={exercise.name}
                                                placeholder="Übungsname eingeben"
                                                onChange={(event) => setExercise({...exercise, name: event.currentTarget.value})}
                                            />
                                        </FormGroup>

                                        <FormGroup className="newExerciseField">
                                            <Form.Label>Beschreibung</Form.Label>
                                            <Textarea
                                                value={exercise.description}
                                                placeholder="Beschreibung eingeben"
                                                onChange={(event) => setExercise({...exercise, description: event.currentTarget.value})}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Form.Label style={{fontWeight: "bold"}}>Material</Form.Label>
                                            <TextInput
                                                value={exercise.material}
                                                placeholder="Material eingeben"
                                                onChange={(event) => setExercise({...exercise, material: event.currentTarget.value})}
                                            />
                                        </FormGroup>
                                    </div>
                                    :
                                    //exercise from DB
                                    <div>
                                        <FormGroup className="newExerciseField">
                                            <Form.Label>Übung/Tätigkeit</Form.Label>
                                            <Select
                                                data={exercises.names}
                                                onChange={nameChangeEvent}
                                                value={exercise.name}
                                                searchable
                                                nothingFound="Keine Einträge gefunden"
                                                placeholder="Wähle eine Übung aus"
                                                clearable
                                            />
                                        </FormGroup>

                                        <FormGroup className="newExerciseField">
                                            <Form.Label>Beschreibung</Form.Label>
                                            <Select
                                                data={exercises.descriptions}
                                                onChange={descChangeEvent}
                                                value={exercise.description}
                                                searchable
                                                nothingFound="Keine Einträge gefunden"
                                                placeholder="Wähle eine Beschreibung aus"
                                                clearable
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Form.Label style={{fontWeight: "bold"}}>Material</Form.Label>
                                            <Select
                                                data={exercises.materials}
                                                onChange={materialChangeEvent}
                                                value={exercise.material}
                                                searchable
                                                nothingFound="Keine Einträge gefunden"
                                                placeholder="Wähle ein Material aus"
                                                clearable
                                            />
                                        </FormGroup>
                                    </div>
                            }
                            </Paper>

                            <Button id="newExerciseBtn" onClick={addExercise}>
                                Übung hinzufügen
                            </Button>
                        </Paper>
                    </Tabs.Panel>
                    <Tabs.Panel value="specific">
                        <Paper radius="xs" style={{marginTop: "1rem"}}>
                            <MantineReactTable
                                table={table}
                            />
                        </Paper>
                    </Tabs.Panel>
                </Tabs>
            </FormWrapper>
            <CustomModal title="Fehlermeldung" body="Diese Tätigkeit befindet sich bereits in der Liste!"
                         decline={false} showModal={showModal} handleOk={handleOk}
                         handleCancel={(() => {})}
            />
        </>
    )
}