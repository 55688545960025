import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import AppHeader from "../header/AppHeader";
import Cookies from "js-cookie";
/**
 * Startpage page of the webapp
 * @author KMU
 */
export default function Startpage() {
    const height = 150;
    const fontSize= "1.5rem";
    const navigate = useNavigate();

    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

        return (
            <div className="startPage">
                <AppHeader bioLogo={true} showHomeIcon={false} showNewPlanIcon={false} showUserIcon={true}/>
                <div className="page-image-div">
                    <img src={require("../../assets/images/image-drei-frauen.png")}
                         alt="image-startpage"
                         className="page-image"
                    />
                </div>
                <div className="content" style={{paddingTop: "4rem"}}>
                    <div style={{display: "flex", gap: "5rem"}}>
                        <div className="icon-text">
                            <Link to="/plans">
                                <img src={require('../../assets/icons/uebersicht.png')} alt="uebersicht-icon"
                                     height={height}/>
                            </Link>
                            <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                                <b>Übersicht <br/> Trainingspläne</b>
                            </p>
                        </div>
                        <div className="icon-text">
                            <Link to="/newPlan">
                                <img src={require("../../assets/icons/neuerplan.png")} alt="neuerplan-icon"
                                     height={height}/>
                            </Link>
                            <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                                <b>Neuen Plan <br/> erstellen</b>
                            </p>
                        </div>
                        <div className="icon-text" style={{filter: "brightness(60%)"}}>
                            <img src={require("../../assets/icons/dashboard.png")} alt="dashboard-icon"
                                 height={height}/>
                            <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                                <b>Dashboard</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
}